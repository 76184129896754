<template>
  <div class="content">
    <a-affix :offset-top="0">
      <div class="top-nav">
        <div class="date-input" @click="showDatePicker">{{ inputValue }}</div>

        <div class="dining-types">
          <div
            :class="{ type: true, on: diningType === 0 }"
            @click="onChangeDiningType(0)"
          >
            全部
          </div>
          <div
            :class="{ type: true, on: diningType === 1 }"
            @click="onChangeDiningType(1)"
          >
            午餐
          </div>
          <div
            :class="{ type: true, on: diningType === 2 }"
            @click="onChangeDiningType(2)"
          >
            晚餐
          </div>
        </div>
      </div>

      <div class="search-box">
        <div class="tab">
          <div :class="{ item: true, on: tabindex === 1 }" @click="onTab(1)">
            客户经理
          </div>
          <div :class="{ item: true, on: tabindex === 2 }" @click="onTab(2)">
            台位
          </div>
          <div :class="{ item: true, on: tabindex === 3 }" @click="onTab(3)">
            姓名
          </div>
          <div :class="{ item: true, on: tabindex === 4 }" @click="onTab(4)">
            手机
          </div>
        </div>
        <div class="tab-body">
          <div class="body-item" v-if="tabindex === 1">
            <div
              :class="{ user: true, on: operator === '' }"
              @click="onChangeOperator('')"
            >
              全部
            </div>
            <div
              :class="{ user: true, on: operator === v.id }"
              v-for="(v, k) in handlers"
              :key="k"
              @click="onChangeOperator(v.id)"
            >
              {{ v.name }}
            </div>
          </div>

          <div class="body-item" v-if="tabindex === 2">
            <select v-model="queryParams.TableId" @change="getDataList(1)" placeholder="选择台位">
              <option v-for="(v, k) in tables" :key="k" :value="v.id">
                {{ v.name }}
              </option>
            </select>
          </div>

          <div class="body-item" v-if="tabindex === 3">

            <div class="form-control">
              <input v-model="queryParams.CustomerName" placeholder="客户姓名" />

              <div class="search-btn"  @click="getDataList(1)">
                <search-outlined />
              </div>
            </div>
          </div>

          <div class="body-item" v-if="tabindex === 4">

            <div class="form-control">
              <input v-model="queryParams.CustomerMobile" placeholder="客户手机号" />

              <div class="search-btn"  @click="getDataList(1)">
                <search-outlined />
              </div>
            </div>
          </div>
        </div>
      </div>

      <van-tabs v-model:active="activeTab" @click-tab="onType">
        <van-tab
          v-for="(v, k) in types"
          :key="k"
          :title="v.title"
          :name="v.value"
        ></van-tab>
      </van-tabs>
    </a-affix>

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        class="list"
      >
        <div
          class="item"
          v-for="(v, k) in list"
          :key="k"
          @click="
            $router.push({ name: 'handlers-order', params: { id: v.id } })
          "
        >
          <div class="top">
            <span>{{ v.customerName }}({{v.customerMobile}})</span>
            <span class="state">{{
              [
                "吧台预定",
                "客户经理预定",
                "待支付",
                "预订成功",
                "开餐",
                "退订",
                "不来",
                "结算完成",
              ][v.orderStatus]
            }}</span>
          </div>
          <div class="info-box">
            <div class="img">
              <img src="@/assets/customer/zoom.jpg" alt="五味楼" />
            </div>
            <div class="info">
              <div class="time">
                {{ $filters.formatDate(v.bookTime, "YYYY-MM-DD HH:mm") }}到店
              </div>
              <div class="name">{{ v.tableName }}</div>
              <div class="combo">
                {{ v.comboPrice }}元/人 * {{ v.peopleCount }}人
              </div>
            </div>
          </div>
          <div class="price-action">
            <div class="remark">
              <span v-if="v.customerRemark">备注：{{ v.customerRemark }}</span>
            </div>
            <div class="price">
              ¥<span>{{ v.payAmount }}</span>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>

    <Footer />

    <van-popup v-model:show="show2" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        :min-date="minDate"
        type="date"
        title="选择日期"
        @confirm="onChangeDate"
      />
    </van-popup>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { SearchOutlined } from '@ant-design/icons-vue'
import Footer from './Footer'
import { List, PullRefresh, Tab, Tabs, Popup, DatetimePicker } from 'vant'
import 'vant/es/tab/style'
import 'vant/es/tabs/style'
import 'vant/es/list/style'
import 'vant/es/pull-refresh/style'
import 'vant/es/popup/style'
import 'vant/es/datetime-picker/style'
import { OrderClass } from '@/apis/order'
import defaultConfig from '@/config/default.config'
import dayjs from 'dayjs'

const order = new OrderClass()
export default defineComponent({
  components: {
    Footer,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    SearchOutlined
  },
  setup () {
    const queryParams = ref({
      Limit: 20,
      OperatorId: ''
    })
    const paginate = ref({
      total: 0,
      page: 1
    })
    const store = ref()
    const list = ref([])
    const loading = ref(false)
    const finished = ref(false)
    const refreshing = ref(false)
    const activeTab = ref(-1)

    const currentDate = ref(new Date())
    const minDate = ref(new Date())
    const show2 = ref(false)
    const inputValue = ref(dayjs().format('YYYY-MM-DD'))

    const onLoad = () => {
      console.log('加载', paginate.value.total, paginate.value.page)
      if (
        paginate.value.page * queryParams.value.Limit >= paginate.value.total ||
        paginate.value.total <= queryParams.value.Limit
      ) {
        finished.value = true
      } else {
        getDataList(++paginate.value.page)
      }
    }

    const getDataList = (page) => {
      loading.value = true
      if (page === 1) {
        list.value = []
      }

      paginate.value.page = page
      const q = { ...queryParams.value }

      q.Offset = (page - 1) * q.Limit
      order.gets(q).then((resp) => {
        list.value.push(...resp.data)

        paginate.value.total = resp.total

        loading.value = false
        refreshing.value = false

        if (page >= resp.total) {
          finished.value = true
        } else {
          finished.value = false
        }
      })
    }

    const onRefresh = () => {
      refreshing.value = true
      getDataList(1)
    }

    const onType = (e) => {
      if (e.name === -1) {
        delete queryParams.value.OrderType
      } else {
        queryParams.value.OrderType = e.name
      }

      getDataList(1)
    }

    const onChangeDate = (e) => {
      show2.value = false
      inputValue.value = dayjs(e).format('YYYY-MM-DD')

      queryParams.value.BeginTime = inputValue.value
      queryParams.value.EndTime = inputValue.value

      getDataList(1)
    }

    const onPlusDay = () => {
      const date = dayjs(currentDate.value).add(1, 'day')
      inputValue.value = date.format('YYYY-MM-DD')
      currentDate.value = new Date(date.format())

      queryParams.value.BeginTime = inputValue.value
      queryParams.value.EndTime = inputValue.value

      getDataList(1)
    }
    const onMinusDay = () => {
      const date = dayjs(currentDate.value).subtract(1, 'day')
      inputValue.value = date.format('YYYY-MM-DD')
      currentDate.value = new Date(date.format())

      queryParams.value.BeginTime = inputValue.value
      queryParams.value.EndTime = inputValue.value

      getDataList(1)
    }

    const diningType = ref(0)
    const onChangeDiningType = (e) => {
      diningType.value = e
      queryParams.value.DiningType = e

      getDataList(1)
    }

    const handlers = ref([])
    const operator = ref('')
    const onChangeOperator = (e) => {
      operator.value = e
      if (e) {
        queryParams.value.OperatorId = e
      } else {
        delete queryParams.value.OperatorId
      }
      getDataList(1)
    }

    const tabindex = ref(0)
    const onTab = (e) => {
      tabindex.value = e
    }

    const onChangeTable = () => {
      console.log(queryParams.value.TableId)
    }

    return {
      queryParams,
      paginate,
      list,
      loading,
      finished,
      refreshing,
      store,
      types: [
        {
          title: '全部',
          value: -1
        },
        {
          title: '预订',
          value: 0
        },
        {
          title: '开餐',
          value: 1
        },
        {
          title: '退订',
          value: 2
        },
        {
          title: '不来',
          value: 3
        },
        {
          title: '已结算',
          value: 4
        }
      ],
      activeTab,

      currentDate,
      show2,
      minDate,
      inputValue,
      diningType,
      handlers,
      operator,
      tabindex,

      onLoad,
      onRefresh,
      onType,
      onChangeDate,
      onPlusDay,
      onMinusDay,
      onChangeDiningType,
      getDataList,
      onChangeOperator,
      onTab,
      onChangeTable
    }
  },

  created () {
    const date = this.$route.query.bookDate
    const diningType = this.$route.query.diningType
    if (date) {
      this.inputValue = date
      this.queryParams.BeginTime = this.inputValue
      this.queryParams.EndTime = this.inputValue
    }

    if (diningType) {
      this.diningType = diningType
      this.queryParams.DiningType = this.diningType
    }

    this.getDataList(1)
    this.getStoreInfo()

    this.getHandlers()
    this.getTables()
  },
  methods: {
    async getStoreInfo () {
      const store = this.$store.state.user.storeInfo.id
        ? this.$store.state.user.storeInfo
        : await this.$store.dispatch('GetStoreInfo', defaultConfig.store_id)

      this.store = store
    },
    async getHandlers () {
      const list =
        this.$store.state.data.ALL_HANDLERS.length > 0
          ? this.$store.state.data.ALL_HANDLERS
          : await this.$store.dispatch('GetAllHandlers')

      this.handlers = list
    },
    async getTables () {
      const list =
        this.$store.state.data.ALL_TABLE.length > 0
          ? this.$store.state.data.ALL_TABLE
          : await this.$store.dispatch('GetAllTable')

      this.tables = list
    }
  }
})
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background: #ffffff;
}
.top-nav {
  height: 0.8rem;
  display: flex;
  align-items: center;
  padding: 0 0.2rem;
  background: #fff;
  .date-input {
    background: #1890ff;
    color: #fff;
    border-radius: 0.1rem;
    padding: 0 0.1rem;
    height: 0.5rem;
    line-height: 0.5rem;
  }
}

.dining-types {
  display: flex;
  justify-content: center;
  background: #ffffff;
  .type {
    width: 1rem;
    height: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 0.05rem;
    margin: 0 0.1rem;
    font-size: 0.24rem;
    color: #666;
    &.on {
      color: #fff;
      background: #1890ff;
      border-color: #1890ff;
    }
  }
}

.search-box {
  background: #ebebeb;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  .tab {
    display: flex;
    background: #fff;
    .item {
      width: 25%;
      height: 0.8rem;
      line-height: 0.8rem;
      text-align: center;
      position: relative;
      color: #999;
      &:before {
        content: "|";
        position: absolute;
        top: 0;
        left: 0;
      }
      &:first-child {
        &:before {
          content: "";
        }
      }
      &.on {
        color: #333;
        &:after {
          content: "";
          display: block;
          width: 80%;
          height: 2px;
          background: #1890ff;
          position: absolute;
          bottom: 0;
          left: 10%;
        }
      }
    }
  }

  .tab-body {
    .body-item {
      min-height: 0.8rem;
      display: flex;
      align-items: center;
      padding: 0 0.2rem;
      flex-wrap: wrap;
      .user {
        padding: 0.05rem 0.2rem;

        &.on {
          color: #fff;
          background: #1890ff;
        }
      }

      select{
        height: 0.6rem;
        width: 100%;
        border-radius: 3px;
      }

      .form-control{
        display: flex;
        width: 100%;
        height: 0.6rem;
        padding-right: 0.6rem;
        border: 1px solid #333;
        box-sizing: border-box;
        position: relative;
        background: #fff;
        border-radius: 3px;
        input{
          display: block;
          width: 100%;
          height: 100%;
          border: none;
        }
        .search-btn{
          position: absolute;
          top: 0;
          right: 0;
          width: 0.6rem;
          height: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: center;

        }
      }
    }
  }
}

.list {
  padding: 0.3rem;
  .item {
    border-radius: 0.1rem;
    box-shadow: 0 0 0.25rem rgba(150, 150, 150, 0.3);
    margin-bottom: 0.3rem;
    background: #ffffff;
    padding: 0.3rem 0.18rem;
    .top {
      font-size: 0.28rem;
      color: #333333;
      line-height: 0.44rem;
      display: flex;
      justify-content: space-between;
      .state {
        font-size: 0.24rem;
      }
    }

    .info-box {
      padding-top: 0.2rem;
      padding-bottom: 0.4rem;
      border-bottom: 1px solid #ebebeb;
      position: relative;
      padding-left: 1.98rem;
      .img {
        width: 1.78rem;
        height: 1.15rem;
        border-radius: 0.08rem;
        overflow: hidden;
        position: absolute;
        top: 0.2rem;
        left: 0;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      .info {
        font-size: 0.24rem;
        position: relative;
        height: 1.15rem;
        .name {
          line-height: 0.6rem;
          font-size: 0.3rem;
        }
        .time {
          line-height: 0.44rem;
        }
        .combo {
          position: absolute;
          bottom: 0;
          right: 0;
          line-height: 0.6rem;
          line-height: 0.4rem;
        }
      }
    }

    .price-action {
      padding-top: 0.28rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .price {
        font-size: 0.36rem;
      }
      .remark {
        font-size: 0.24rem;
        width: 4rem;
      }
    }
  }
}
</style>
